<template>
    <div>
        <!-- S: 게시판 -->
        <v-data-table v-bind="{ headers, items }" dense hide-default-footer disable-filtering disable-sort @click:row="(any, { item }) => showBoard(item)" no-data-text="작성된 문의사항이 없습니다" mobile-breakpoint="768" class="v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit v-data-table--cursor-pointer">
            <template #[`item.subject`]="{ item }">
                <div class="text-left">
                    <div data-idx="5" class="text-truncate">
                        <v-chip small outlined class="mr-12px"> {{ item.typeText }} </v-chip> {{ item.subject }}
                    </div>
                </div>
            </template>
            <template #[`item.createdAt`]="{ item }">
                <span>{{ item.createdAt.toDate() }}</span>
            </template>
            <template #[`item.actions`]="{ item }">
                <span v-if="item.reply" class="primary--text">답변완료</span>
                <span v-else class="grey--text text--lighten-1">답변대기</span>
            </template>
        </v-data-table>
        <!-- E: 게시판 -->

        <!-- S: 버튼 -->
        <div class="btn-wrap">
            <v-row justify="center" class="row--xs">
                <v-spacer />
                <v-col cols="auto">
                    <v-btn outlined color="grey darken-4" :to="{ query: { type, mode: 'form' } }">문의하기</v-btn>
                </v-col>
            </v-row>
        </div>
        <!-- E: 버튼 -->

        <list-search v-bind="{ code, loading, hideTypeFilter }" />

        <!-- S: 페이징 -->
        <div class="pagination-wrap pagination-wrap--sm">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="search" />
        </div>
        <!-- E: 페이징 -->
    </div>
</template>

<script>
import { btn_primary3, CENTER_QUESTION_TYPES } from "@/assets/variables";
import ListSearch from "./list-search.vue";
import api from "@/api";

const headers = [
    { text: "제목", value: "subject", align: "center", cellClass: "left" },
    { text: "작성일", value: "createdAt", align: "center", width: 200 },
    { text: "상태", value: "actions", align: "center", width: 120 },
];

export default {
    components: {
        ListSearch,
    },
    props: {
        code: { type: String },
        hideTypeFilter: { type: Boolean, default: false },
    },
    data: () => ({
        questions: [],

        limit: 10,
        summary: { totalCount: 0 },

        headers,
        btn_primary3,

        loading: false,
    }),
    computed: {
        items() {
            return [...this.questions];
        },
        type() {
            return this.$route.query.type;
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            query.code = this.code;
            return { ...query };
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.questions = [];
            this.limit = 10;
            this.summary = { totalCount: 0 };
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { skip, limit, params } = this;
                const { summary, questions } = await api.v1.center.questions.gets({ header: { skip, limit }, params });

                this.summary = summary;
                this.questions = questions;
            } finally {
                this.loading = false;
            }
        },
        showBoard(item) {
            const { type } = this;
            this.$router.push({ query: { type, _question: item._id, mode: "view" } });
        },
    },
};
</script>
