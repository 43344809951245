var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-data-table', _vm._b({
    staticClass: "v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit v-data-table--cursor-pointer",
    attrs: {
      "dense": "",
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-sort": "",
      "no-data-text": "작성된 문의사항이 없습니다",
      "mobile-breakpoint": "768"
    },
    on: {
      "click:row": function (any, _ref) {
        var item = _ref.item;
        return _vm.showBoard(item);
      }
    },
    scopedSlots: _vm._u([{
      key: `item.subject`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "text-left"
        }, [_c('div', {
          staticClass: "text-truncate",
          attrs: {
            "data-idx": "5"
          }
        }, [_c('v-chip', {
          staticClass: "mr-12px",
          attrs: {
            "small": "",
            "outlined": ""
          }
        }, [_vm._v(" " + _vm._s(item.typeText) + " ")]), _vm._v(" " + _vm._s(item.subject) + " ")], 1)])];
      }
    }, {
      key: `item.createdAt`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('span', [_vm._v(_vm._s(item.createdAt.toDate()))])];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [item.reply ? _c('span', {
          staticClass: "primary--text"
        }, [_vm._v("답변완료")]) : _c('span', {
          staticClass: "grey--text text--lighten-1"
        }, [_vm._v("답변대기")])];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false)), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey darken-4",
      "to": {
        query: {
          type: _vm.type,
          mode: 'form'
        }
      }
    }
  }, [_vm._v("문의하기")])], 1)], 1)], 1), _c('list-search', _vm._b({}, 'list-search', {
    code: _vm.code,
    loading: _vm.loading,
    hideTypeFilter: _vm.hideTypeFilter
  }, false)), _c('div', {
    staticClass: "pagination-wrap pagination-wrap--sm"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }