var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm._t("default", null, null, {
    attrs: {
      loading: _vm.loading
    },
    on: {
      click: _vm.remove
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }