<template>
    <u-dialog v-model="shows" title="정회원 전환요청" persistent width="600">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-tabs-items v-model="tab">
            <v-tab-item :value="TABS.FETCHING.value">
                <v-fade-transition>
                    <v-overlay v-show="loading" absolute light color="white">
                        <v-progress-circular indeterminate size="100" width="5" color="primary" />
                    </v-overlay>
                </v-fade-transition>
            </v-tab-item>
            <v-tab-item :value="TABS.ENTERING.value">
                <v-card rounded="md" color="primary lighten-5">
                    <div class="pa-16px">
                        <div class="font-size-16 font-weight-bold primary--text mb-8px">정회원 혜택</div>
                        <p class="txt txt--xs">아래의 내용을 토대로 정회원에 등록하고 신규회원을 모집하면 신규회원 구매 비용의 일부와 등업 시 발생한 보너스를 출금할 수 있는 서비스</p>
                    </div>
                </v-card>
                <v-divider class="my-18px my-md-24px" />
                <tab-form v-model="form" v-bind="{ loading }" v-on="{ save }" />
            </v-tab-item>
            <v-tab-item :value="TABS.AWAITING.value">
                <div class="txt txt--xs txt--dark font-weight-medium text-center py-20px py-md-0">정회원 전환요청이 접수되었습니다</div>
                <div class="btn-wrap">
                    <v-btn v-bind="{ loading }" block color="primary" class="v-size--xx-large" @click="close"> 창닫기 </v-btn>
                </div>
            </v-tab-item>
            <v-tab-item :value="TABS.REJECTED.value">
                <div class="txt txt--xs txt--dark font-weight-medium text-center py-20px py-md-0">
                    정회원 전환요청이 거부되었습니다 <br />
                    고객센터에 별도 문의 부탁드립니다.
                </div>
                <div class="btn-wrap">
                    <v-btn v-bind="{ loading }" block color="primary" class="v-size--xx-large" @click="close"> 창닫기 </v-btn>
                </div>
            </v-tab-item>
        </v-tabs-items>
    </u-dialog>
</template>

<script>
import api from "@/api";
import { initPromoterApprovalInquiry, INQUIRY_STATES } from "@/assets/variables";

import TabForm from "./tab-form.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";

const TABS = {
    FETCHING: { value: "FETCHING", text: "로딩" },
    ENTERING: { value: "ENTERING", text: "입력" },
    ...INQUIRY_STATES,
};

export default {
    components: {
        TabForm,
        UDialog,
    },
    data: () => ({
        form: initPromoterApprovalInquiry(),

        // ui
        tab: TABS.FETCHING.value,
        shows: false,
        loading: false,

        TABS,
    }),
    watch: {
        shows(shows) {
            if (shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;

            this.loading = true;
            this.tab = TABS.FETCHING.value;
            try {
                this.form = ((await api.v1.me.inquiries.gets())?.inquiries || [])[0] || initPromoterApprovalInquiry();
                if (this.form?._id) this.tab = TABS[this.form?.state]?.value;
                else this.tab = TABS.ENTERING.value;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async save() {
            if (this.loading) return;

            this.loading = true;
            try {
                let { inquiry } = await api.v1.me.inquiries.post(this.form);
                this.form = inquiry;

                if (this.form?._id) this.tab = TABS[this.form?.state]?.value;
                else this.tab = TABS.ENTERING.value;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        close() {
            this.shows = false;
        },
    },
};
</script>

<style></style>
