<template>
    <v-card tile color="primary" class="white--text">
        <div class="pa-18px px-md-10px pa-lg-24px">
            <div class="pb-18px pb-md-24px text-center">
                <div class="font-size-18 font-size-md-22 font-weight-bold line-height-1">{{ user?.name }} 님</div>
                <!-- <div class="font-size-14 line-height-1 mt-8px">{{ user?.username }}</div> -->
            </div>
            <v-divider style="border-color: rgba(255, 255, 255, 0.15) !important; background-color: transparent !important" />
            <page-section class="page-section--xs pb-0">
                <v-row v-for="({ term, data, type }, index) in items" :key="term" align="center" justify="space-between" class="row--sm font-size-14" :class="{ 'mt-8px': index != 0 }">
                    <v-col cols="auto">{{ term }}</v-col>
                    <v-col cols="auto" v-if="type == 'chip'">
                        <v-chip x-small outlined color="white">{{ data }}</v-chip>
                    </v-col>
                    <v-col cols="auto" v-else>{{ data }}</v-col>
                </v-row>
            </page-section>

            <page-section class="page-section--xs pb-0">
                <template v-if="isDashboard">
                    <v-btn block color="white" to="/mypage" class="grey--text text--darken-4"> 일반회원 페이지로 이동 </v-btn>
                </template>
            </page-section>
        </div>
    </v-card>
</template>

<script>
import { mapActions } from "vuex";

import PageSection from "@/components/client/templates/page-section.vue";
import PromoterForm from "../mypage/promoter/promoter-form.vue";

export default {
    components: {
        PageSection,
        PromoterForm,
    },
    props: {
        isDashboard: { type: Boolean, default: false },
    },
    data: () => ({}),
    computed: {
        user() {
            return this.$store.state.user;
        },
        items() {
            const items = [];

            items.push({ term: "회원등급", data: this.user?.level?.name });
            items.push({ term: "적립금", data: `${this.user?.point?.format?.() || 0}원`, type: "chip" });

            return items;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions(["getUser"]),
        async init() {
            this.getUser();
        },
    },
};
</script>

<style></style>
