<template>
    <div v-bind="{ loading }">
        <tit-form-section v-model="form" v-bind="{ items }" @input="emit">
            <!--  -->
        </tit-form-section>
        <page-section class="page-section--xs pb-0">
            <terms-of-agreements-short v-model="form._terms" :params="termsParams" @input="emit" />
        </page-section>
        <div class="btn-wrap">
            <v-btn block x-large color="primary" v-bind="{ loading }" @click="$emit('save')"> 정회원으로 전환 요청하기 </v-btn>
        </div>
    </div>
</template>

<script>
import { attrs_input, initPromoterApprovalInquiry } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import TitFormSection from "@/components/dumb/tit-form-section.vue";
import TermsOfAgreementsShort from "@/components/client/join/terms-of-agreements-short.vue";

const termsParams = { code: ["member-management", "personal-information-collection", "choose"] };

export default {
    components: {
        PageSection,
        TitFormSection,
        TermsOfAgreementsShort,
    },
    props: {
        value: { type: Object, default: initPromoterApprovalInquiry },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        form: initPromoterApprovalInquiry(),

        termsParams,
    }),
    computed: {
        items() {
            return [
                { term: "출금계좌", type: "bank", key: "bank", hasVerification: true },
                { term: "주민등록번호", type: "rrn", key: "residentRegistrationNumber" },
            ].map((item) => ({ ...attrs_input, ...item }));
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = { ...this.value };
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep{
    .page-section--xs .container{
        padding: 0;
    }
}
</style>