<template>
    <v-card tag="aside" rounded="" elevation="4" width="100%" class="overflow-hidden">
        <aside-user-card isDashboard />
        <aside-navigation />
    </v-card>
</template>

<script>
import AsideUserCard from "@/components/client/dumb/aside-user-card.vue";
import AsideNavigation from "@/components/client/dumb/aside-navigation.vue";

export default {
    components: {
        AsideUserCard,
        AsideNavigation,
    },
};
</script>

<style></style>
