var render = function render(){
  var _vm$question;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.question ? _c('div', [_c('v-simple-table', {
    staticClass: "v-data-table--default v-data-table--no-hover w-100"
  }, [_c('tbody', [_c('tr', [_c('th', [_c('span', {
    staticClass: "break-words"
  }, [_vm._v(_vm._s(_vm.question.subject))])])]), _c('tr', [_c('td', [_c('div', {
    staticClass: "divider-group"
  }, [_c('ul', [_c('li', [_vm._v("문의유형 : " + _vm._s(_vm.question.typeText))]), _c('li', [_vm._v("작성일 : " + _vm._s(_vm.question.createdAt.toDate()))])])])])]), _vm.question.content ? _c('tr', [_c('td', [_c('div', {
    staticClass: "min-h-100px min-h-md-150px",
    domProps: {
      "innerHTML": _vm._s(_vm.question.content.replace(/\n/g, '<br>'))
    }
  })])]) : _vm._e(), _vm.question.reply ? _c('tr', [_c('td', [_c('div', {
    staticClass: "min-h-100px min-h-md-150px",
    domProps: {
      "innerHTML": _vm._s(_vm.question.reply.replace(/\n/g, '<br>'))
    }
  })])]) : _vm._e()])]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey darken-4",
      "exact": "",
      "to": {
        query: {
          type: _vm.type,
          _question: (_vm$question = _vm.question) === null || _vm$question === void 0 ? void 0 : _vm$question._id,
          mode: 'form'
        }
      }
    }
  }, [_vm._v("수정하기")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('remove', {
    on: {
      "search": _vm.list
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "outlined": "",
            "color": "grey darken-4",
            "exact": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v("삭제하기")])];
      }
    }], null, false, 3144911647),
    model: {
      value: _vm.question,
      callback: function ($$v) {
        _vm.question = $$v;
      },
      expression: "question"
    }
  })], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    },
    on: {
      "click": _vm.list
    }
  }, [_vm._v("리스트")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }