<template>
    <div class="tit-wrap--line">
        <div class="pb-12px pb-md-20px">
            <v-row align="center" class="row--sm">
                <div class="col col-auto">
                    <div v-if="this.$slots['titleContents']">
                        <slot name="titleContents" />
                    </div>
                    <h3 v-else class="tit tit--xs">{{ title }}</h3>
                </div>
                <v-spacer v-if="this.$slots['default']" />
                <div v-if="this.$slots['default']" class="col col-auto">
                    <slot />
                </div>
            </v-row>
        </div>
        <v-divider v-if="!noDivider" style="border-width:2px 0 0;" class="grey darken-4" />
    </div>
</template>

<script>
export default {
    props: {
        title: { type: String },
        noDivider: { type: Boolean },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
