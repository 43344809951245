<template>
    <div class="mb-20px mb-40px">
        <v-card color="grey lighten-5">
            <div class="pa-20px">
                <v-row align="center" justify="center" class="row--sm">
                    <v-col cols="12" sm="auto">
                        <v-row align="center" class="row--xs">
                            <v-col cols="auto" v-if="!hideTypeFilter">
                                <v-select v-model="query.type" :items="types" v-bind="{ loading }" clearable hide-details dense outlined placeholder="전체" class="w-120px" />
                            </v-col>
                            <v-col cols="">
                                <v-text-field v-model="query.searchValue" v-bind="{ loading }" placeholder="검색어를 입력하세요" clearable hide-details dense outlined @keydown.enter="emit" />
                            </v-col>
                            <v-col cols="12" sm="auto">
                                <v-btn v-bind="{ ...btn_primary3, ...$attrs, loading }" class="w-100 w-sm-80px" @click="emit">검색</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </v-card>
    </div>
</template>

<script>
import { btn_primary3, CENTER_QUESTION_TYPES } from "@/assets/variables";

const initQuery = (query = {}) => ({
    ...query,
    type: query.type || null,
    searchKey: query.searchKey || null,
    searchValue: query.searchValue || null,
});

export default {
    props: {
        code: { type: String, default: null },
        loading: { type: Boolean, default: false },
        hideTypeFilter: { type: Boolean, default: false },
    },
    data: () => ({
        btn_primary3,

        query: initQuery(),
    }),
    computed: {
        types() {
            return Object.values(CENTER_QUESTION_TYPES[this.code]);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query || {};

            if (!query?.type) delete query.type;
            if (!query?.searchKey) delete query.searchKey;
            if (!query?.searchValue) delete query.searchValue;

            delete query.page;

            this.$router.push({ query });
        },
    },
};
</script>
