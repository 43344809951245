<template>
    <div>
        <v-simple-table class="v-data-table--default v-data-table--no-hover w-100">
            <tbody>
                <tr>
                    <th>
                        <v-row align="center" class="row--xs">
                            <v-col cols="12" sm="auto">
                                <v-select v-model="question.type" v-bind="{ ...attrs_input, loading }" :items="questionTypes" placeholder="문의유형" class="w-sm-200px" />
                            </v-col>
                            <v-col cols="12" sm="">
                                <v-text-field v-model="question.subject" v-bind="{ ...attrs_input, loading }" :disabled="!question.type" placeholder="제목을 입력해주세요" />
                            </v-col>
                        </v-row>
                    </th>
                </tr>
                <tr>
                    <td>
                        <v-textarea v-model="question.content" v-bind="{ ...attrs_input, loading }"></v-textarea>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <div class="btn-wrap">
            <v-row class="row--xs">
                <v-col cols="auto">
                    <v-btn outlined color="grey" v-bind="{ loading }" @click="$router.go(-1)">취소</v-btn>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <v-btn color="primary" v-bind="{ loading }" @click="save">확인</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { attrs_input, CENTER_QUESTION_TYPES } from "@/assets/variables";

const initForm = (form = {}) => ({
    ...(form || {}),

    type: form?.type ?? null,
    subject: form?.subject ?? null,
    content: form?.content ?? null,
});

export default {
    props: {
        code: { type: String },
    },
    data: () => ({
        question: initForm(),

        loading: false,

        attrs_input,
    }),
    computed: {
        type() {
            return this.$route.query.type;
        },
        _question() {
            return this.$route.query._question;
        },
        isCreate() {
            return !this._question;
        },
        questionTypes() {
            return Object.values(CENTER_QUESTION_TYPES[this.code]);
        },
    },
    methods: {
        async sync() {
            this.question = initForm({
                type: this.type,
            });
        },
        async init() {
            if (this.isCreate) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                let { question } = await api.v1.center.questions.get({ _id: this._question });
                this.question = initForm(question);
            } finally {
                this.loading = false;
            }
        },
        async save() {
            try {
                if (!this.question.type) throw new Error("문의유형을 선택해주세요");
            } catch (error) {
                this.$handleError(error);
                return;
            }

            if (this.loading) return;
            else this.loading = true;

            try {
                const { code } = this;
                const { post, put } = api.v1.center.questions;
                await (this.isCreate ? post : put)({ ...this.question, code });

                if (this.isCreate) alert("문의가 등록되었습니다");
                else alert("문의가 수정되었습니다");

                this.$router.go(-1);
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        _question() {
            this.sync().then(this.init);
        },
    },
    mounted() {
        this.sync().then(this.init);
    },
};
</script>
