<template>
    <span>
        <slot
            v-bind="{
                attrs: { loading },
                on: { click: remove },
            }"
        />
    </span>
</template>

<script>
import api from "@/api";
export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        loading: false,
    }),
    methods: {
        async remove() {
            const go = confirm("해당 문의를 삭제하시겠습니까?");
            if (!go) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                await api.v1.center.questions.delete({ _id: this.value?._id });

                alert("해당 문의가 삭제되었습니다");

                this.$emit("search");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style>
</style>